import { cn } from '@rouvydev/web-components/utils'
import { type VariantProps, cva } from 'class-variance-authority'

const spinnerVariants = cva('animate-spin', {
  variants: {
    variant: {
      primary: '',
      light: '',
      dark: '',
    },
    size: {
      xbig: 'h-[92px] w-[92px] stroke-[12px]',
      big: 'h-[74px] w-[74px] stroke-[10px]',
      medium: 'h-14 w-14 stroke-[8px]',
      small: 'h-12 w-12 stroke-[8px]',
      xsmall: 'h-[38px] w-[38px] stroke-[6px]',
      xxsmall: 'h-7 w-7 stroke-[4px]',
    },
    speed: {
      fast: 'duration-500',
      medium: 'duration-1000',
      slow: 'duration-1500',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'medium',
    speed: 'medium',
  },
})

const variants = {
  primary: { bg: { color: '#311573', opacity: '' }, spinner: '#6F10E7' },
  light: { bg: { color: '#FFF', opacity: '0.17' }, spinner: '#FFF' },
  dark: { bg: { color: '#100D10', opacity: '0.17' }, spinner: '#110728' },
}

const sizes = {
  xbig: {
    bgPath:
      'M86 46C86 51.2529 84.9654 56.4543 82.9552 61.3073C80.945 66.1604 77.9986 70.5699 74.2843 74.2843C70.5699 77.9986 66.1604 80.945 61.3073 82.9552C56.4543 84.9654 51.2529 86 46 86C40.7471 86 35.5457 84.9654 30.6927 82.9552C25.8396 80.945 21.4301 77.9986 17.7157 74.2843C14.0014 70.5699 11.055 66.1604 9.04482 61.3073C7.03463 56.4543 6 51.2529 6 46C6 40.7471 7.03463 35.5457 9.04482 30.6927C11.055 25.8396 14.0014 21.4301 17.7157 17.7157C21.4301 14.0014 25.8396 11.055 30.6927 9.04482C35.5457 7.03463 40.7471 6 46 6C51.2529 6 56.4543 7.03463 61.3073 9.04482C66.1604 11.055 70.5699 14.0014 74.2843 17.7157C77.9986 21.4301 80.945 25.8396 82.9552 30.6927C84.9654 35.5457 86 40.7471 86 46L86 46Z',
    spinnerPath:
      'M86 46C86 54.4472 83.3258 62.6775 78.3607 69.5114C73.3956 76.3453 66.3944 81.4319 58.3607 84.0423',
    size: 92,
  },
  big: {
    bgPath:
      'M69 37C69 41.2023 68.1723 45.3635 66.5641 49.2459C64.956 53.1283 62.5989 56.6559 59.6274 59.6274C56.6559 62.5989 53.1283 64.956 49.2459 66.5641C45.3634 68.1723 41.2023 69 37 69C32.7977 69 28.6365 68.1723 24.7541 66.5641C20.8717 64.956 17.3441 62.5989 14.3726 59.6274C11.4011 56.6559 9.044 53.1283 7.43585 49.2459C5.8277 45.3634 5 41.2023 5 37C5 32.7977 5.82771 28.6365 7.43586 24.7541C9.04401 20.8717 11.4011 17.3441 14.3726 14.3726C17.3441 11.4011 20.8717 9.044 24.7541 7.43585C28.6366 5.8277 32.7977 5 37 5C41.2023 5 45.3635 5.82771 49.2459 7.43586C53.1283 9.04401 56.6559 11.4011 59.6274 14.3726C62.5989 17.3441 64.956 20.8717 66.5641 24.7541C68.1723 28.6366 69 32.7977 69 37L69 37Z',
    spinnerPath:
      'M69 37C69 43.7577 66.8606 50.342 62.8885 55.8091C58.9164 61.2763 53.3155 65.3456 46.8885 67.4338',
    size: 74,
  },
  medium: {
    bgPath:
      'M52 28C52 31.1517 51.3792 34.2726 50.1731 37.1844C48.967 40.0962 47.1992 42.742 44.9706 44.9706C42.742 47.1992 40.0962 48.967 37.1844 50.1731C34.2726 51.3792 31.1517 52 28 52C24.8483 52 21.7274 51.3792 18.8156 50.1731C15.9038 48.967 13.258 47.1992 11.0294 44.9706C8.80083 42.742 7.033 40.0962 5.82689 37.1844C4.62078 34.2726 4 31.1517 4 28C4 24.8483 4.62078 21.7274 5.82689 18.8156C7.03301 15.9038 8.80083 13.258 11.0294 11.0294C13.258 8.80083 15.9038 7.033 18.8156 5.82689C21.7274 4.62078 24.8483 4 28 4C31.1517 4 34.2726 4.62078 37.1844 5.82689C40.0962 7.03301 42.742 8.80083 44.9706 11.0294C47.1992 13.258 48.967 15.9038 50.1731 18.8156C51.3792 21.7274 52 24.8483 52 28L52 28Z',
    spinnerPath:
      'M52 28C52 33.0683 50.3955 38.0065 47.4164 42.1068C44.4373 46.2072 40.2366 49.2592 35.4164 50.8254',
    size: 56,
  },
  small: {
    bgPath:
      'M44 24C44 26.6264 43.4827 29.2272 42.4776 31.6537C41.4725 34.0802 39.9993 36.285 38.1421 38.1421C36.285 39.9993 34.0802 41.4725 31.6537 42.4776C29.2272 43.4827 26.6264 44 24 44C21.3736 44 18.7728 43.4827 16.3463 42.4776C13.9198 41.4725 11.715 39.9993 9.85786 38.1421C8.00069 36.285 6.5275 34.0802 5.52241 31.6537C4.51731 29.2272 4 26.6264 4 24C4 21.3736 4.51732 18.7728 5.52241 16.3463C6.5275 13.9198 8.00069 11.715 9.85787 9.85786C11.715 8.00069 13.9198 6.5275 16.3463 5.52241C18.7728 4.51731 21.3736 4 24 4C26.6264 4 29.2272 4.51732 31.6537 5.52241C34.0802 6.5275 36.285 8.00069 38.1421 9.85787C39.9993 11.715 41.4725 13.9198 42.4776 16.3463C43.4827 18.7728 44 21.3736 44 24L44 24Z',
    spinnerPath:
      'M44 24C44 28.2236 42.6629 32.3388 40.1803 35.7557C37.6978 39.1727 34.1972 41.716 30.1803 43.0211',
    size: 48,
  },
  xsmall: {
    bgPath:
      'M35 19C35 21.1011 34.5861 23.1817 33.7821 25.1229C32.978 27.0641 31.7994 28.828 30.3137 30.3137C28.828 31.7994 27.0641 32.978 25.1229 33.7821C23.1817 34.5861 21.1011 35 19 35C16.8988 35 14.8183 34.5861 12.8771 33.7821C10.9359 32.978 9.17203 31.7994 7.68629 30.3137C6.20055 28.828 5.022 27.0641 4.21793 25.1229C3.41385 23.1817 3 21.1011 3 19C3 16.8988 3.41385 14.8183 4.21793 12.8771C5.022 10.9359 6.20056 9.17203 7.68629 7.68629C9.17203 6.20055 10.9359 5.022 12.8771 4.21793C14.8183 3.41385 16.8989 3 19 3C21.1012 3 23.1817 3.41385 25.1229 4.21793C27.0641 5.022 28.828 6.20056 30.3137 7.68629C31.7994 9.17203 32.978 10.9359 33.7821 12.8771C34.5861 14.8183 35 16.8989 35 19L35 19Z',
    spinnerPath:
      'M35 19C35 22.3789 33.9303 25.671 31.9443 28.4046C29.9582 31.1381 27.1578 33.1728 23.9443 34.2169',
    size: 38,
  },
  xxsmall: {
    bgPath:
      'M26 14C26 15.5759 25.6896 17.1363 25.0866 18.5922C24.4835 20.0481 23.5996 21.371 22.4853 22.4853C21.371 23.5996 20.0481 24.4835 18.5922 25.0866C17.1363 25.6896 15.5759 26 14 26C12.4241 26 10.8637 25.6896 9.4078 25.0866C7.95189 24.4835 6.62902 23.5996 5.51472 22.4853C4.40042 21.371 3.5165 20.0481 2.91345 18.5922C2.31039 17.1363 2 15.5759 2 14C2 12.4241 2.31039 10.8637 2.91345 9.4078C3.5165 7.95189 4.40042 6.62902 5.51472 5.51472C6.62902 4.40041 7.95189 3.5165 9.4078 2.91345C10.8637 2.31039 12.4241 2 14 2C15.5759 2 17.1363 2.31039 18.5922 2.91345C20.0481 3.5165 21.371 4.40042 22.4853 5.51472C23.5996 6.62902 24.4835 7.95189 25.0866 9.4078C25.6896 10.8637 26 12.4241 26 14L26 14Z',
    spinnerPath:
      'M26 14C26 16.5342 25.1977 19.0033 23.7082 21.0534C22.2187 23.1036 20.1183 24.6296 17.7082 25.4127',
    size: 28,
  },
}

interface SpinnerProps extends VariantProps<typeof spinnerVariants> {
  className?: string
}

export function Spinner({ variant, size, speed, className }: SpinnerProps) {
  const selectedSize = sizes[size ?? 'medium']

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={selectedSize.size}
      height={selectedSize.size}
      viewBox={`0 0 ${selectedSize.size} ${selectedSize.size}`}
      fill="none"
      className={cn(spinnerVariants({ size, speed }), className)}
    >
      <path
        d={selectedSize.bgPath}
        stroke={variants[variant ?? 'primary'].bg.color}
        strokeOpacity={variants[variant ?? 'primary'].bg.opacity}
      />
      <path
        d={selectedSize.spinnerPath}
        stroke={variants[variant ?? 'primary'].spinner}
        strokeLinecap="round"
      />
    </svg>
  )
}
